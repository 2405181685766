@font-face {
  font-family: 'Proxima Nova';
  src: url('../node_modules/font-proxima-nova/fonts/ProximaNova-Regular.woff') format('woff'),
    url('../node_modules/font-proxima-nova/fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../node_modules/font-proxima-nova/fonts/ProximaNova-Bold.woff') format('woff'),
    url('../node_modules/font-proxima-nova/fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  /* margin: 0; */
  margin: auto;
  font-family: 'Proxima Nova', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f4f0;
  color: #333;
  overflow-x: hidden;
}

#root {
  min-height: 100vh;
}
