.updatePasswordContainer {
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.updatePasswordTitle {
  font-size: 26px;
  font-weight: bold;
  color: #008445;
  margin-bottom: 25px;
  text-align: center;
}

.inputGroup {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.inputGroup label {
  font-size: 15px;
  font-weight: 600;
  color: #008445;
  margin-bottom: 6px;
}

.inputGroup input {
  padding: 12px;
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.inputGroup input:focus {
  border-color: #008445;
  box-shadow: 0 0 5px rgba(0, 132, 69, 0.2);
}

.updateButton {
  width: 100%;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  background-color: #00c264;
  border: none;
  border-radius: 8px;
  padding: 12px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.updateButton:hover {
  background-color: #008445;
}

.errorMessage,
.successMessage {
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}

.errorMessage {
  color: #d9534f;
}

.successMessage {
  color: #5cb85c;
}

.updatePasswordForm {
  width: 100%; /* Ensures form fills the container */
  display: flex;
  flex-direction: column;
  align-items: center;
}
