.summary-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 150px;
}

.summary-link {
  text-decoration: none; /* 移除下划线 */
}

.summary-value {
  font-size: 2.5rem;
  font-weight: bold;
  color: #374151;
  cursor: pointer; /* 悬停时显示为可点击状态 */
}

.summary-value:hover {
  color: #1a73e8; /* 悬停时改变颜色 */
}

.summary-title {
  font-size: 1.1rem;
  margin: 5px 0;
  color: #4a5568;
}

.summary-subtitle {
  font-size: 1rem;
  color: #6b7280;
}

.summary-fallrate {
  font-size: 1.1rem;
  color: #4a5568;
  /* margin-top: 8px; */
  /* margin-bottom: 2px; */
  font-weight: bold;
}
