.dashboard {
  margin: auto;
  width: 95%; /* 不再限制宽度，允许其在视口中占满空间 */
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-x: auto;
}

h1,
h2 {
  color: #2c3e50;
}
.chart-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 35px;
}
.chart {
  flex: 1; /* Make charts grow equally */
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
}
.gauge-container {
  text-align: center;
}
.gauge-title {
  font-size: 18px;
  margin-bottom: 10px;
}
.gauge {
  width: 300px;
  height: 150px;
  margin: 0 auto;
  position: relative;
}
.gauge-value {
  position: absolute;
  top: 50%;
  left: 50%;
  padding-top: 65px;
  transform: translate(-50%, -50%);
  font-size: 86px;
  font-weight: bold;
}
.gauge-scale {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
}

.update-button,
.download-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.download-button {
  float: right;
  margin-top: 0;
}
select {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
#fallsLineChart {
  display: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 12px;
  max-height: 100px;
  overflow: auto;
}
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #e8f5e9;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
.logout-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1000;
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gauge-label {
  font-size: 34px; /* Adjust this size if you want it bigger */
  font-weight: bold; /* Make the text bold */
  text-align: center;
  margin-top: -20px; /* Adjust the spacing as needed */
  color: #333; /* Ensure the color matches */
}

.cellRed {
  background-color: #f8b9c6;
  color: white;
}

/* Control the background and positioning of the modal */
.modal {
  position: fixed; /* Fixed positioning, does not scroll with the page */
  top: 50%; /* 50% from the top of the page */
  left: 50%; /* 50% from the left of the page */
  transform: translate(-50%, -50%); /* Move to the center both horizontally and vertically */
  background-color: #fff; /* Background color is white */
  padding: 20px; /* Padding */
  z-index: 1000; /* Ensure the modal is above other elements */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow effect to the modal */
  border-radius: 8px; /* Rounded corners */
  width: 400px; /* Width of the modal */
  max-width: 100%; /* Ensure it doesn't exceed the viewport on smaller screens */
}

/* Control the layout of the content inside the modal */
.modalContent {
  display: flex;
  flex-direction: column; /* Arrange content vertically */
}

/* Style for the textarea */
.modal textarea {
  margin-top: 10px;

  width: 95%; /* Take up the full width of the parent element */
  height: 100px; /* Height set to 100px */
  padding: 10px; /* Add some padding inside the textarea */
  border-radius: 4px; /* Rounded corners */
  border: 1px solid #ccc; /* Border color */
  font-size: 16px; /* Font size */
}

/* Style for the buttons */
.modal button {
  margin-top: 10px; /* Add some spacing above the button */
  padding: 10px 15px; /* Add padding inside the button */
  background-color: #4caf50; /* Button background color */
  color: white; /* Button text color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Show pointer on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s ease; /* Add transition effect */
}

/* Change background color when the mouse hovers over the button */
.modal button:hover {
  background-color: #45a049;
}

/* Style for the Cancel button, typically with a different color */
.modal button:last-child {
  background-color: #f44336; /* Cancel button background color is red */
}

/* Change the cancel button color on hover */
.modal button:last-child:hover {
  background-color: #d32f2f;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
